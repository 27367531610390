import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/mnt/d/ShapeGanicGatsby/src/components/blog/layout.js";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Disclosure from "../../../components/blog/legal/affiliateMarketing.mdx";
import BlogOverview from "../../../components/blog/overview/blogOverview.mdx";
export const query = graphql`
  {
    mdx(slug: { eq: "how-do-i-calculate-my-calorie-needs/" }) {
      frontmatter {
        main_image {
          publicURL
        }
        image_first {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_first_alt
        image_first_desc
        image_first_credit_text
        image_first_credit_link
        image_second {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_second_alt
        image_second_desc
        image_second_credit_text
        image_second_credit_link
        image_third {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_third_alt
        image_third_desc
        image_third_credit_text
        image_third_credit_link
        image_app_promotion {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_app_promotion_alt
        image_app_promotion_desc
        image_app_promotion_credit_text
        image_app_promotion_credit_link
        date(formatString: "MMMM D, YYYY")
      }
      fields {
        readingTime {
          minutes
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SEO = makeShortcode("SEO");
const Title = makeShortcode("Title");
const Image = makeShortcode("Image");
const Listing = makeShortcode("Listing");
const NavLinkGlobal = makeShortcode("NavLinkGlobal");
const AffiliateLink = makeShortcode("AffiliateLink");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO lang={props.pageContext.frontmatter.lang} description={props.pageContext.frontmatter.summary} title={props.pageContext.frontmatter.title} keywords={props.pageContext.frontmatter.keywords} imageUrl={props.data.mdx.frontmatter.main_image.publicURL} mdxType="SEO" />
    <Title title={props.pageContext.frontmatter.title} date={props.data.mdx.frontmatter.date} readingTime={props.data.mdx.fields.readingTime.minutes} mdxType="Title" />
    <p>
  <b>{props.pageContext.frontmatter.summary}</b> Learn clearly with the help of
  examples how you can calculate your basal metabolic rate, physical activity
  level and maintenance calories. The calculation is too time-consuming for you?
  At the end of the article you will learn which app is the most reliable for
  calculating your calories to maintain weight.
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_first)} imageAlt={props.data.mdx.frontmatter.image_first_alt} imageDesc={props.data.mdx.frontmatter.image_first_desc} imageAuthor={props.data.mdx.frontmatter.image_first_credit_text} imageLink={props.data.mdx.frontmatter.image_first_credit_link} mdxType="Image" />
    <h2>{`What Is a Calorie in Food?`}</h2>
    <p>
  Before you learn how to calculate your basal metabolic rate, make sure you
  understand what calories are and what they mean for your body.
    </p>
    <p>
  You see them on virtually all foods, at least the industrially produced ones.{" "}
  <b>Nutrition facts</b> tell you what macronutrients are present in a food.
  Specifically, they show you how many calories a serving of the food provides.
  These calories are also called <b>caloric value</b> and basically tell you how
  much energy is provided to your body by it. Calories are often listed on
  nutrition labels as <b>kcal</b>, where the <b>k</b> stands for kilo as in
  kilograms, but this is neglected in common usage. But what does all this have
  to do with energy and why does your body need this energy at all?
    </p>
    <p>
  Whether you're thinking, walking, standing, sleeping, breathing, running,
  sitting or just getting excited. Yes, even for digestion your body needs one
  thing: <b>energy</b>. This energy must be supplied from the outside and that
  is through food. In food, the caloric value, or energy value, is expressed in
  kilojoules (kJ) or in the more common term calories (kcal). Your body gets
  energy from food indirectly through the macronutrients <b>fat</b>,{" "}
  <b>carbohydrates</b> and <b>protein</b>. <b>1 g of fat</b> provides{" "}
  <b>9.3 calories</b> while both <b>carbohydrates</b> and <b>protein</b> provide{" "}
  <b>4.1 calories</b> per <b>gram</b>.
    </p>
    <h2>{`What Does Your Basal Metabolic Rate Mean?`}</h2>
    <p>
  Imagine lying on the couch all day watching Netflix. You don't even move your
  fingers to change the season of your favorite show. Now you think to yourself
  that you wouldn't consume any calories, after all you haven't done anything.
  Far from it! Even if you do absolutely nothing, your body still needs energy
  to live. Whether it's metabolic processes like digestion or regeneration,
  thought processes or regulating and maintaining your body temperature. All of
  this consumes energy and the totality of this energy has a name. It is the
  basal metabolic rate. Below you will learn how to calculate your basal
  metabolic rate.
    </p>
    <h3>{`How Do I Calculate My Basal Metabolic Rate?`}</h3>
    <p>
  All roads lead to Rome. The same applies to the calculation of the basal
  metabolic rate. There are several formulas for this, some of which provide
  widely differing results. Very rudimentary formulas only take into account the
  body weight and leave age, height and gender out of consideration. Formulas
  that provide reliable results are, for example, the somewhat outdated{" "}
  <b>Harris-Benedict equation</b>. An updated version of this equation based on
  it is the <b>Mifflin-St.Jeor equation</b>. Since it should be your top
  priority to always use the latest scientific findings, you will get to know
  the <b>Mifflin-St.Jeor formula</b> in the following.
    </p>
    <p>
  <Listing mdxType="Listing">
    10 &#215; [Weight in kilograms] + 6.25 &#215; [Height in cm] – 5 &#215; [Age
    in years] + S
  </Listing>
  The constant <b>S</b> is <b>-161 for women</b> and <b>5 for men</b>. And don't
  worry if something is unclear to you. In a moment you will see how easy it is to
  use the formula with an example.
    </p>
    <p>
  If you are from a region of the world that does not use the metric system, you
  will need to convert your height in feet to centimeters as follows:
  <Listing mdxType="Listing">Height in cm = Height in feet &#215; 30.48</Listing>
  You must also first convert your body weight in pounds to kilograms in order
  to use the above formula. You do this as follows:
  <Listing mdxType="Listing">Weight in kilograms = Weight in pounds &#215; 0.453592</Listing>
    </p>
    <h4>{`Sample Calculation - Basal Metabolic Rate`}</h4>
    <p>
  You learn best by examples? You are not alone, so now you will be presented
  with an example calculation. You'll see how easy it is to apply the{" "}
  <b>Mifflin-St. Jeor equation</b>.
    </p>
    <p>
  Consider the following example: a <b>20-year-old woman</b> with a height of{" "}
  <b>5.6 feet</b> and a weight of <b>154 pounds</b>. Putting this into the
  formula above gives:
  <Listing mdxType="Listing">Height in cm = 5.6 &#215; 30.48 = 170 centimeters</Listing>
  <Listing mdxType="Listing">Weight in kilograms = 154 &#215; 0.453592 = 70 kilograms</Listing>
  <Listing mdxType="Listing">
    10 &#215; 70 + 6.25 &#215; 170 – 5 &#215; 20 - 161 = 1501.5 calories
  </Listing>
  Also, remember that you use <b>-161</b> for <b>S</b> on a woman.
    </p>
    <p>
  We therefore obtain a value of <b>1501.5 calories</b> for the{" "}
  <b>basal metabolic rate</b>. This is the amount of energy you need to supply
  your body in order to maintain the most necessary bodily functions. Physical
  activities are therefore not taken into account here. We will deal with this
  in the next section.
    </p>
    <h2>{`What Is Meant by Physical Activity Level?`}</h2>
    <p>
  The average calories burned a day can be divided into two components:{" "}
  <b>basal metabolic rate</b> and <b>physical activity level</b>. You have
  already learned about and calculated the basal metabolic rate. But what is the
  physical activity level?
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_second)} imageAlt={props.data.mdx.frontmatter.image_second_alt} imageDesc={props.data.mdx.frontmatter.image_second_desc} imageAuthor={props.data.mdx.frontmatter.image_second_credit_text} imageLink={props.data.mdx.frontmatter.image_second_credit_link} mdxType="Image" />
    <p>
  Remember that basal metabolic rate is the amount of calories you use when you
  do absolutely nothing. Physical activity level is the amount of energy you use
  during the day to perform any physical activity. This includes standing,
  walking, running, carrying groceries, riding a bike, lifting weights, and so
  on. So, in summary, an active lifestyle, whether through work or sports,
  increases the amount of calories you consume in addition to your basal
  metabolic rate.
    </p>
    <p>
  Here, too, there are different approaches to calculating the calories burned
  by the physical activity level. On the one hand, there are mathematically
  quite complex procedures and those that are somewhat simpler. Experience shows
  that the complicated models do not lead to a better result. The reason for
  this is that the respective activity levels must be estimated by you in each
  case. Also, not every day is like the previous one. So in each case you get an
  estimated value, unlike the rather exact calculation of the basal metabolic
  rate. For this reason, we will do without the time weighting of the activity
  level and in the next section we will get to know a method with which one
  comes to equivalent results.
    </p>
    <h3>{`How Do You Calculate Your Physical Activity Level?`}</h3>
    <p>
  To determine the calories burned by the physical activity level, you need to
  estimate your daily activity level. Below you will find an official and
  scientifically determined table in which you can read your PAL value. PAL
  stands for <b>Physical Activity Level</b> and expresses as a number how high
  your daily physical activity level is. So in the <b>Activity Level</b> column
  you choose the description of the daily activity that best describes your
  situation. Here you will also see an example of how you can proceed.
    </p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Activity Level`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`PAL Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Examples`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`exclusively sedentary or lying lifestyle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1,2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`old, frail people`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`exclusively sedentary with little or no strenuous leisure activity`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1,4-1,5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`office workers, precision mechanics`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`sedentary work, at times also additional energy expenditure for walking or standing activities`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1,6-1,7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`laboratory technicians, drivers, students, assembly line workers`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`predominantly walking and standing work`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1,8-1,9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`housewives, salesmen, waiters, mechanics, craftsmen`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`physically demanding professional work`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2,0-2,4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`construction workers, farmers, forest workers, miners, competitive athletes`}</td>
        </tr>
      </tbody>
    </table>
    <p>
  However, before you are presented with an example, you should know what the
  PAL value means mathematically. A <b>PAL value</b> of e.g. <b>1.4</b>{" "}
  expresses that you have consumed <b>40% more calories</b> in addition to the
  basal metabolic rate. If you multiply the basal metabolic rate by the PAL
  value, you would calculate your maintenance calories. However, to calculate
  only the <b>calories burned by physical activity</b>, you must{" "}
  <b>subtract 1 from the PAL value</b> and then multiply this value by the basal
  metabolic rate calculated above. You can see this in the following formula.
  <Listing mdxType="Listing">
    calories burned by physical activity = basal metabolic rate &#215; (PAL
    value - 1)
  </Listing>
    </p>
    <p>
  In addition to the activity level in the table above, which mainly describes
  your professional and everyday physical activity, a sporting leisure activity
  is insufficiently taken into account here.{" "}
  <b>
    You can add 0.3 PAL units per day for sports activities or for strenuous
    leisure activities that take place about 4 to 5 times a week with a duration
    of 30 to 60 minutes.
  </b>{" "}
  But now it's time for an example, so you can understand exactly how to
  calculate your calories burned by physical activity.
    </p>
    <h4>{`Sample Calculation - Physical Activity Level`}</h4>
    <p>
  Let's take the <b>woman</b> from the above calculation of the basal metabolic
  rate as an example. The <b>basal metabolic rate</b> is <b>1501.5 calories</b>,
  the lady is an <b>office worker</b> (PAL value 1.4) and goes to the{" "}
  <b>gym 4 times a week for about 1 hour</b>. So we add 0.3 to the 1.4. So we
  get a <b>PAL value of 1.7</b>. But since we only want to calculate the
  calories burned by physical activity, we subtract 1 and get 0.7. This is the
  value with which we multiply the basal metabolic rate.
  <Listing mdxType="Listing">
    1501.5 calories &#215; (1.4 + 0.3 - 1) = 1501.5 calories &#215; 0.7 =
    1051.05 calories
  </Listing>
  We obtain a value of <b>1051.05 calories</b> as the{" "}
  <b>calories burned by physical activity</b>.
    </p>
    <h2>{`How Do You Calculate Maintenance Calories?`}</h2>
    <p>
  The question that probably interests you most, however, is how many calories
  does the average person burn a day. Your actual average calorie intake is the
  sum of basal metabolic rate and calories burned by physical activity, as you
  can see from this simple equation.
  <Listing mdxType="Listing">
    maintenance calories = basal metabolic rate + calories burned by physical
    activity
  </Listing>
  So if you were to consume this amount of calories daily, you would neither
  lose nor gain weight, your body weight would remain constant. The calculation
  of the maintenance calories looks almost the same as the calculation of the
  calories burned by physical activity.
  <Listing mdxType="Listing">
    maintenance calories = basal metabolic rate &#215; PAL value
  </Listing>
  The only difference is that you do <b>not</b> subtract 1 from the PAL value.
  The average calorie intake is therefore the basal metabolic rate including all
  physical activities.
    </p>
    <h3>{`Sample Calculation - Calories to Maintain Weight`}</h3>
    <p>
  Again, you will see how easy it is to calculate the average calories burned a
  day with the help of an example. Again, let's take the lady from above as an
  example with a basal metabolic rate of <b>1501.5 calories</b> and a{" "}
  <b>PAL value of 1.7</b>. As a reminder, she is an <b>office worker</b> with a
  PAL value of 1.4 and does sports several times a week, which adds 0.3 PAL
  units. So the calculation of the maintenance calories looks like this:
  <Listing mdxType="Listing">
    1501.5 calories &#215; (1.4 + 0.3) = 1501.5 calories &#215; 1.7 = 2552.55
    calories
  </Listing>
  The average calorie intake of the office worker who goes to the gym 4 times a
  week for one hour is <b>2552.55 calories</b>. This value is also called{" "}
  <b>maintenance calories</b>. If she ate this many calories on average every
  day, her weight would remain constant.
    </p>
    <p>
  So you see, calculating your calorie needs is not rocket science. Of course,
  it would be much more convenient if there was an{" "}
  <NavLinkGlobal to="/#appstores" mdxType="NavLinkGlobal">app</NavLinkGlobal> that could do it for you.
  It would be best if this <NavLinkGlobal to="/#appstores" mdxType="NavLinkGlobal">app</NavLinkGlobal>{" "}
  would also count the calories you eat every day at the same time. Fortunately,
  there is such a{" "}
  <NavLinkGlobal to="/#appstores" mdxType="NavLinkGlobal">calorie counter</NavLinkGlobal>. And the nice
  thing is that it's completely free and can be used without any restrictions.
  What are you waiting for?{" "}
  <NavLinkGlobal to="/#appstores" mdxType="NavLinkGlobal">
    The best thing is to start now!
  </NavLinkGlobal>
    </p>
    <h2>{`FAQ - Frequently Asked Questions`}</h2>
    <p>
  Do you know the feeling when you've been reading up on a topic for what feels
  like an eternity, but then you realize with disappointment that exactly{" "}
  <b>YOUR</b> particular question remains unanswered? I know this only too well,
  that's why you get here a question-answer catalog with which even your most
  specific question will be answered. Let's go!
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_third)} imageAlt={props.data.mdx.frontmatter.image_third_alt} imageDesc={props.data.mdx.frontmatter.image_third_desc} imageAuthor={props.data.mdx.frontmatter.image_third_credit_text} imageLink={props.data.mdx.frontmatter.image_third_credit_link} mdxType="Image" />
    <h3>{`What Should I Do if My BMR Is Low?`}</h3>
    <p>
  When your basal metabolic rate is higher, your body also consumes more
  calories at rest. This is practical because it makes it easier for you to stay
  slim and maintain your desired weight. Also, a higher basal metabolic rate
  helps you lose weight if that's your goal. It also allows you to eat more and
  let's face it, who doesn't like to do that!
    </p>
    <p>
  You have seen that your basal metabolic rate depends on your gender, age,
  height and weight. Except for weight, you cannot influence the other
  parameters. An increase in weight therefore leads to a higher basal metabolic
  rate. But this is where the wheat is separated from the chaff. An increase in
  fat actually also leads to an increased basal metabolic rate. The reason for
  this is that body fat is tissue that also needs to be supplied with nutrients
  and calories. But there is no question here that this is an unhealthy and
  unaesthetic solution. The only reasonable alternative is for you to{" "}
  <b>build muscle</b>. This will help you metabolize more calories in the long
  run, even at rest. It also helps to relieve your passive musculoskeletal
  system in the long term and also has an aesthetic benefit.
    </p>
    <h3>{`How Many Calories Does My Body Burn a Day?`}</h3>
    <p>
  After you have calculated your average calorie intake, you now want to know if
  it corresponds to your actual calorie needs. To determine this, you have two
  options. The first option is to track everything you eat for two weeks. That
  is, you check how many calories you eat per day. In addition, you should weigh
  yourself every 2nd or 3rd day. If you determine that your weight has remained
  more or less constant, this amount of calories corresponds to your actual
  maintenance calories. If, on the other hand, you have gained weight, the
  amount of energy you are consuming is higher than your total caloric needs. If
  you have lost weight, you have eaten less than your actual maintenance
  calories.
    </p>
    <p>
  Another and probably much more convenient and sustainable approach is to use a{" "}
  <AffiliateLink url={props.pageContext.frontmatter.product_fitness_tracker_url} mdxType="AffiliateLink">
    {props.pageContext.frontmatter.product_fitness_tracker_text}
  </AffiliateLink>
  . The pleasant thing about this alternative is that it happens entirely passively.
  You put on the tracker and the measurement of your calorie consumption is determined
  using very accurate sensors and algorithms. The body values measured in this way
  result in extremely reliable and accurate values. You can test both options and
  see what suits you best.
    </p>
    <h3>{`How Many Calories Should I Eat a Day for Losing Weight?`}</h3>
    <p>
  You have determined your calories to maintain weight and would now like to
  know how many calories you need to reduce it by in order to lose weight
  sustainably and healthily? A very good empirical value here is{" "}
  <b>300 calories</b>. This gives you a weekly deficit of 2100 calories and you
  would lose <b>1.2 kilograms of pure body fat</b> per month. This means that
  our lady from above with a maintenance calories value of{" "}
  <b>2552.55 calories</b>, would use a calorie amount for her diet of about{" "}
  <b>2250 calories</b>. You may hear from some self-proclaimed experts that this
  deficit is too low. But be very careful here. A deficit of 300 calories
  ensures that your metabolism does not fall asleep and that you also get pretty
  full every day. This helps you enormously to carry out the diet over a longer
  period of time in a relaxed manner. Because only a diet that you really
  complete is also a good diet!
    </p>
    <h3>{`How Much Calories Should I Eat to Gain Muscle?`}</h3>
    <p>
  To gain weight, that is, to build muscle, you need a slight caloric surplus.
  This process is a balancing act, because your goal is not to get fat, but to
  build muscle in a controlled and clean way. The basis for this is to determine
  your maintenance calories. Here it is very likely that this is already
  sufficient at the beginning to build muscle, at least in the initial phase.
  This is because it has been calculated in such a way that practically every
  one of your days will be the same. Since you probably don't work or do sports
  every day, your real consumption will probably be lower. So you would already
  have your slight surplus here.
    </p>
    <p>
  If your weight gain comes to a halt, increase your average calorie intake by{" "}
  <b>300 to 500 calories</b>. You should keep an eye on your body weight and
  your body measurements. Especially your body fat percentage should not
  increase noticeably. An average{" "}
  <b>body weight gain of 500 grams per month (approx. 1 pound)</b> should not
  significantly be exceeded. Beginners will see faster results in the beginning.
  But for advanced athletes this value should not be exceeded. 500 grams per
  month would be 6 kilograms of muscle per year. That would be 60 kilograms of
  pure muscle after 10 years. Everyone can imagine that this is unrealistic.
  Your goal should therefore be to build quality muscles in a controlled and
  fat-free manner!
    </p>
    <h2>{`What Did You Learn From This Article?`}</h2>
    <p>
  In this article you have learned everything you need to know about calculating
  your calorie needs. Now you have sound knowledge and know the differences
  between basal metabolic rate, physical activity level and maintenance
  calories. The following points summarize briefly what you should remember!
    </p>
    <ul>
      <li parentName="ul">{`Basal Metabolic Rate tells you how many calories you consume at rest without exercise.`}</li>
      <li parentName="ul">{`The physical activity level indicates how many calories you need in addition to the basal metabolic rate due to physical activities.`}</li>
      <li parentName="ul">{`Your maintenance calories are derived from your basal metabolic rate and your physical activity level.`}</li>
      <li parentName="ul">{`You can increase your basal metabolic rate by gaining muscle.`}</li>
      <li parentName="ul">
        <AffiliateLink url={props.pageContext.frontmatter.product_fitness_tracker_url} mdxType="AffiliateLink">
  {props.pageContext.frontmatter.product_fitness_tracker_text}
        </AffiliateLink> can help you to determine the average calories burned a day.
      </li>
    </ul>
    <Disclosure mdxType="Disclosure" />
    <h2>{`Lose Weight and Build Muscle`}</h2>
    <p>
  You read articles like this one because you have real goals. You want to
  achieve something, something that is important to you. You want to lose some
  weight to feel more comfortable, to be healthier, or to please yourself more.
  You want to build up muscles in order to have a slim and beautiful physique in
  the future. Supplements can be very helpful here, but at the end of the day,
  your <b>total daily calories</b> and the <b>macronutrient distribution</b>{" "}
  make the difference between <b>success and failure</b>!
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_app_promotion)} imageAlt={props.data.mdx.frontmatter.image_app_promotion_alt} imageDesc={props.data.mdx.frontmatter.image_app_promotion_desc} imageAuthor={props.data.mdx.frontmatter.image_app_promotion_credit_text} imageLink={props.data.mdx.frontmatter.image_app_promotion_credit_link} mdxType="Image" />
    <h3>{`Total Calories and Macronutrient Distribution`}</h3>
    <p>
  To stay on track, you need to control your calories and keep an eye on them.
  But also the macronutrient distribution, i.e. the ratio of eaten protein,
  carbohydrates and fats, is extremely important. Determining these values daily
  by hand in a spreadsheet is very time-consuming and frustrating. To help you
  out, I'm providing you with a 100% free{" "}
  <NavLinkGlobal to="/#appstores" mdxType="NavLinkGlobal">calorie counter app</NavLinkGlobal>. It also
  helps you to log your weight and analyze it graphically. What are you waiting
  for? <NavLinkGlobal to="/#appstores" mdxType="NavLinkGlobal">Start NOW!</NavLinkGlobal>
    </p>
    <BlogOverview mdxType="BlogOverview" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      